import Styles from "./Loader.module.css";
export type LoaderProps = {
  visible: boolean;
};
const Loader: React.FC<LoaderProps> = ({ visible }) => {
  return (
    <div
      id="loader"
      className={Styles.loader}
      style={{ display: visible ? "block" : "none" }}
    ></div>
  );
};

export default Loader;
