import { EventInput } from "@fullcalendar/react";
import { firestore } from "_core/firebase/firebase";

export const getEventList = async () => {
  // Create a reference to the cities collection
  const eventRefs = firestore.collection("events");

  // Create a query against the collection
  const snap = await eventRefs.get();

  const events: EventInput[] = [];
  snap.forEach((doc) => {
    const data = doc.data();

    events.push({
      id: doc.id,
      title: data.title,
      extendedProps: {
        description: data.description,
        author: data.author,
        keywords: data.keywords,
        updateDate: data.updateDate.toDate(),
        meta: data.meta,
      },
      start: data.start.toDate(),
      end: data.end.toDate(),
      allDay: data.allDay,
      textColor: data.textColor || "white",
      backgroundColor: data.backgroundColor || "#3788d8",
      borderColor: data.borderColor || "#3788d8"    
    });
  });

  return events;
};
