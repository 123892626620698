import FullCalendar, { EventInput, EventClickArg, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import listPlugin from '@fullcalendar/list';
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import ModalService from "_common/component/modal/ModalService";
import EventDetail from "./EventDetail";

let eventGuid = new Date().getTime();
//let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export function createEventId() {
  return String(eventGuid++);
}

function renderEventContent(eventContent: EventContentArg) {
  return (
    <div style={{ lineHeight: "1.8rem", overflow: "hidden" }}>
      <b>{eventContent.timeText}</b>
      <i style={{ marginLeft: 5 }}> {eventContent.event.title}</i>
    </div>
  );
}
type CalendarMainProps = {
  initialEvents: EventInput[];
};
const CalendarMain: React.FC<CalendarMainProps> = ({ initialEvents }) => {
  const handleEventClick = (clickInfo: EventClickArg) => {
    ModalService.open({
      title: `Evènement ${clickInfo.event.title}`,

      content: <EventDetail event={clickInfo.event} />,
      showSaveButton: false,
      cancelLabel: "Fermer",
      onSave: async () => true,
      onCancel: async () => true,
    });
  };

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listYear",
        }}
        locale={frLocale}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        businessHours={{ daysOfWeek: [1, 2, 3, 4, 5], startTime: false, endTime: false }}
        initialEvents={initialEvents} // alternatively, use the `events` setting to fetch from a feed
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
      />
    </div>
  );
};

export default CalendarMain;
