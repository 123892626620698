import { EventInput } from "@fullcalendar/react";
import { useEffect, useState } from "react";
import Calendar from "./Calendar";
import { getEventList } from "./CalendarService";
import Styles from "./CalendarPage.module.css";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ROUTE_ACCOUNT } from "_core/router/routes";

const CalendarPage = () => {
  const [initialEvents, setInitialEvents] = useState<EventInput[]>();

  useEffect(() => {
    getEventList().then((events) => {
      setInitialEvents(events);
    });
  }, []);

  return (
    <>
      <div className={Styles.AppBar}>
        <div className={Styles.AppBarContent}>
          <Link to={`/`}>
            <img
              title="Revenir à la page d'accueil"
              className={Styles.Logo}
              alt="Logo du club ACF"
              src="/img/logoACF.svg"
            ></img>
          </Link>
          <span className={Styles.TitlePart}>Calendrier du club</span>
        </div>
      </div>
      <div className={clsx(Styles.TopMenu)} key="topmenu">
        <span>
          <Link className="space-left space-right" to={"/"}>
            Accueil
          </Link>
        </span>
        <span>
          <span className={Styles.TopMenuSeparator}>|</span>
          <Link className="space-left space-right" to={ROUTE_ACCOUNT}>
            Mon compte
          </Link>
        </span>
      </div>
      <section className={Styles.Calendar}>
        {initialEvents ? <Calendar initialEvents={initialEvents} /> : <div />}
      </section>
    </>
  );
};

export default CalendarPage;
