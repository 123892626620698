import { CarouselItem } from "business/carousel/CarouselMain";
import { firestore } from "_core/firebase/firebase";


export type Topic = {
  id: string;
  label: string;
  title: string;
  subTitle: string;
  catchPhrase: string;
  thumbnail: string;
  backgroundMedia: string;
  backgroundColor: string;
  isActive: boolean;
  isPrivate: boolean;
  updateDate?: Date;
  articles?: string[];
  relatedTopics?: string[];
  relatedTopicsTitle?: string;
  carousel?: CarouselItem[];
};

export type TopicInfos = {
  id: string;
  label: string;

  title: string;
  catchPhrase: string;
  isActive: boolean;
  isPrivate:boolean;
  updateDate?: Date;
  thumbnail: string;
};

export const getTopicDetail = async (id: string) => {
  const doc = await firestore.collection("topics").doc(id).get();

  const data = doc.data();
  if (!data) {
    return undefined;
  }

  return {
    id: doc.id,
    label: data.label,
    thumbnail: data.thumbnail || "",
    backgroundMedia: data.backgroundMedia || "",
    backgroundColor: data.backgroundColor || "",
    catchPhrase: data.catchPhrase || "",
    title: data.title || data.label || "",
    subTitle: data.subTitle || "",
    isActive: data.isActive || false,
    isPrivate: data.isPrivate || false,
    updateDate: data.updateDate,
    articles: data.articles ? data.articles : [],
    relatedTopicsTitle: data.relatedTopicsTitle || "",
    relatedTopics: data.relatedTopics ? data.relatedTopics : [],
    carousel: data.carousel || [],
  } as Topic;
};

export const getTopic = async (id: string) => {
  try {
    const doc = await firestore.collection("topics").doc(id).get();

    const data = doc.data();
    if (!data) {
      return undefined;
    }
  
    return {
      id: doc.id,
      label: data.label,
      thumbnail: data.thumbnail || "",
      catchPhrase: data.catchPhrase || "",
      title: data.title || data.label || "",
      isActive: data.isActive || false,
      isPrivate: data.isPrivate || false,
      updateDate: data.updateDate,
    } as TopicInfos;
  } catch (err) {
    console.error(err);
    return undefined;
  }
  
};


let _topics: TopicInfos[] | undefined = undefined;

export const getTopicListOrCache = async (isAuthenticated:boolean) => {
  if (_topics) {
    return _topics;
  } else {
    return await getTopicList(isAuthenticated);
  }
}

export const getTopicList = async (isAuthenticated:boolean) => {

  let query = firestore.collection("topics").where("isActive", "==", true);
  if (!isAuthenticated) {
    query = query.where("isPrivate", "!=", true);
  }
  const snapshot = await query.get();

  const topics: TopicInfos[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();

    topics.push({
      label: data.label,
      catchPhrase: data.catchPhrase,
      id: doc.id,
      thumbnail: data.thumbnail || "",
      title: data.title || data.label || "",
      isActive: data.isActive || false,
      isPrivate: data.isPrivate || false,
      updateDate: data.updateDate,
    });
  });


  _topics = topics;
  return topics;
};

export const findTopic = (topicId: string | undefined, topics: TopicInfos[]) => {
  if (!topicId) {
    return undefined;
  }
  const found = topics.filter((t) => t.id === topicId);
  if (found && found.length > 0) {
    return found[0];
  } else {
    return undefined;
  }
};

export const getTopicInfosById = (topicList: TopicInfos[]) => (id: string) => {
  return findTopic(id, topicList || []);
};
