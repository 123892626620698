export interface LoaderConfig {
  visible: boolean;
}

class LoaderServiceImpl {
  private LoaderProviderHandler: ((conf: LoaderConfig) => void) | undefined;

  public show = () => {
    this.LoaderProviderHandler &&
      this.LoaderProviderHandler({
        visible: true,
      });
  };

  public hide = () => {
    this.LoaderProviderHandler &&
      this.LoaderProviderHandler({
        visible: false,
      });
  };

  setLoaderProvider = (handler: (conf: LoaderConfig) => void) => {
    if (this.LoaderProviderHandler) {
      // eslint-disable-next-line no-console
      console.error("The LoaderProvider handles only one Handler...");
    }
    this.LoaderProviderHandler = handler;
  };
}

const LoaderService = new LoaderServiceImpl();

export default LoaderService;
