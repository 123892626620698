import React, { Suspense, useEffect, useState } from "react";
import Styles from "./App.module.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "../router/routes";
import LoaderProvider from "_common/component/loader/LoaderProvider";
import ScrollTop from "_core/router/component/ScrollTop";
import ModalProvider from "_common/component/modal/ModalProvider";
import NotificationProvider from "_common/component/notification/NotificationProvider";
import { getTopicList, TopicInfos } from "business/topic/service/TopicService";
import { useUser } from "_core/authent/LoggedUserService";
import LoaderService from "_common/component/loader/LoaderService";

function App() {
  const { isAuthenticated, isLoading } = useUser();
  const [topics, setTopics] = useState<TopicInfos[] | undefined>();

  useEffect ( ()=>{
    getTopicList(isAuthenticated).then((topics) => {
     
      setTopics(topics);
    }).finally(()=>{
      LoaderService.hide();
    });
  },[isAuthenticated, isLoading]);

  if (isLoading || !topics) {
    LoaderService.show();
    return <div />;
  }

  return (
    <div className={Styles.App}>
      <Router>
        <ScrollTop>
          <Switch>
            {routes.map((route) => {
              return (
                <Route key={route.path} path={route.path} exact={route.exact}>
                  <>
                    <div className={Styles.AppContent}>
                      <Suspense fallback={<div></div>}>
                        <route.component />
                      </Suspense>
                    </div>
                  </>
                </Route>
              );
            })}
          </Switch>
        </ScrollTop>
      </Router>
      <ModalProvider />
      <NotificationProvider />
      <LoaderProvider />
    </div>
  );
}

export default App;
