import { useCallback, useEffect, useState } from "react";
import {
  auth,
  firestore,
  FirebaseUser,
  FirebaseAuthError,
} from "_core/firebase/firebase";
import { DbUser, User } from "./types";

export const signIn = async (user: string, pass: string) => {
  try {
    const res = await auth.signInWithEmailAndPassword(user, pass);

    return res;
  } catch (err) {
    throw err;
  }
};

export const signOut = async () => {
  await auth.signOut();
};

export const useOnAuthStateChanged = (
  stateChangeListener: (
    user: FirebaseUser | null,
    error: FirebaseAuthError | undefined
  ) => void
) => {
  useEffect(() => {
    return auth.onAuthStateChanged(
      (user: FirebaseUser | null) => {
        stateChangeListener(user, undefined);
      },
      (error: FirebaseAuthError) => {
        stateChangeListener(null, error);
      }
    );
  }, [stateChangeListener]);
};

export const useOnIdTokenChanged = (
  stateChangeListener: (
    user: FirebaseUser | null,
    error: FirebaseAuthError | undefined
  ) => void
) => {
  useEffect(() => {
    return auth.onIdTokenChanged(
      (user: FirebaseUser | null) => {
        stateChangeListener(user, undefined);
      },
      (error: FirebaseAuthError) => {
        stateChangeListener(null, error);
      }
    );
  }, [stateChangeListener]);
};

export const getDbUserDetail = async (uid: string) => {
  const doc = await firestore.collection("users").doc(uid).get();

  if (!doc.exists) {
    return {};
  } else {
    return doc.data() as DbUser;
  }
};

export const useUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null | undefined>();
  const [error, setError] = useState<FirebaseAuthError | null | undefined>();
  useOnAuthStateChanged(
    useCallback(
      async (user, err) => {
       
        if (user && user.uid) {
          setUser({
            uid: user.uid,
            email: user.email || undefined,
            emailVerified: user.emailVerified,
            displayName: user.displayName || undefined,
            phoneNumber: user.phoneNumber || undefined,
            photoURL: user.photoURL || undefined,
            disabled: false,
            metadata: user.metadata,
            customClaims: {},
            dbdata: await getDbUserDetail(user.uid),
          });
         
        } else {
          setUser(null);
        }
        setIsLoading(false);
        setError(err);
      },
      [setUser, setError]
    )
  );
  useOnIdTokenChanged(
    useCallback(
      async (user, err) => {
        if (user && user.uid) {
          setUser({
            uid: user.uid,
            email: user.email || undefined,
            emailVerified: user.emailVerified,
            displayName: user.displayName || undefined,
            phoneNumber: user.phoneNumber || undefined,
            photoURL: user.photoURL || undefined,
            disabled: false,
            metadata: user.metadata,
            customClaims: {},
            dbdata: await getDbUserDetail(user.uid),
          });
        } else {
          setUser(null);
        }
        setError(err);
      },
      [setUser, setError]
    )
  );

  return { user, isLoading, isAuthenticated: !!user, error };
};
