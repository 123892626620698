import React, { useEffect } from "react";
import clsx from "clsx";
import Style from "./Notification.module.css";

export type TypeNotification =
  | "is-danger"
  | "is-success"
  | "is-warning"
  | "is-info"
  | "is-primary";

type NotificationProps = {
  typeNotification: TypeNotification;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  duration: number;
};

const Notification: React.FC<NotificationProps> = ({
  typeNotification,
  onClose,
  duration,
  children,
}) => {
  useEffect(() => {
    if (duration > 0) {
      const t = setTimeout(() => {
        onClose(undefined);
      }, duration);

      return () => clearTimeout(t); // return clear timeout handler
    }
    return undefined;
  }, [duration, onClose]);

  return (
    <div
      className={clsx("notification", Style.Notification, typeNotification)}
      style={{ marginBottom: 8 }}
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <button className="delete" onClick={onClose} type="button" />
      {children}
    </div>
  );
};

export default Notification;
