import React, { useState, useEffect } from "react";
import LoaderService from "./LoaderService";
import Loader from "./Loader";

type LoaderProviderProps = {
  // add props here
};

const LoaderProvider: React.FC<LoaderProviderProps> = () => {
  const [visible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    LoaderService.setLoaderProvider((conf) => {
      setIsVisible(conf.visible);
    });
  }, []);

  return <Loader visible={visible} />;
};

export default LoaderProvider;
