import { EventApi } from "@fullcalendar/common";
import { format } from "date-fns";
import frenchLocale from "date-fns/locale/fr";

type EventDetailProps = {
  event: EventApi;
};

const EventDetail: React.FC<EventDetailProps> = ({ event }) => {
  const dateDebut = (event.start && format(event.start, "d MMM yyyy HH:mm", { locale: frenchLocale })) || "";
  const dateFin = (event.end && format(event.end, "d MMM yyyy HH:mm", { locale: frenchLocale })) || "";

  return (
    <>
      <form>
        <div className="field">
          <label className="label">Titre</label>
          <div className="control">
            <input
              disabled={true}
              defaultValue={event.title}
              className="input"
              type="text"
              placeholder="Titre de l'évènement"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">{event.allDay ? "Date" : "Date de début"}</label>
              <div className="control">
                <input disabled={true} defaultValue={dateDebut} className="input" type="text" />
              </div>
            </div>
            {event.allDay && (
              <div className="field" style={{ display: "block" }}>
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" defaultChecked={true} disabled={true} style={{ marginRight: 5 }} />
                    Journée entière
                  </label>
                </div>
              </div>
            )}
          </div>

          {!event.allDay && (
            <div className="column">
              <div className="field">
                <label className="label">Date de fin</label>
                <div className="control">
                  <input defaultValue={dateFin} className="input" type="text" disabled={true} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="field">
          <label className="label">Description</label>
          <div className="control">
            <textarea
              defaultValue={event.extendedProps?.description || ""}
              disabled={true}
              className="textarea"
            ></textarea>
          </div>
        </div>
      </form>
    </>
  );
};

export default EventDetail;
