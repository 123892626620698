import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_REASUREMENT_ID,
};

const siteApp = firebase.initializeApp(config, "site");
// remove this comment to activate analytics
//firebase.analytics(siteApp);
export const firestore = firebase.firestore(siteApp);
export const storage = firebase.storage(siteApp);
export const auth = firebase.auth(siteApp);

if (process.env.NODE_ENV === "production") {
  firestore.settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  });
}

export type Result<T = any, E = any> =
  | {
      isOk: true;
      isError: false;
      payload?: T;
    }
  | {
      isOk: false;
      isError: true;
      error?: E;
    };
export function ValidResult<T = any>(payload?: T): Result<T> {
  return {
    isOk: true,
    isError: false,
    payload: payload,
  };
}
export function ErrResult<T = any, E = any>(err?: E): Result<T, E> {
  return {
    isOk: false,
    isError: true,
    error: err,
  };
}

export interface FirebaseUser extends firebase.User {}
export interface FirebaseAuthError extends firebase.auth.Error {}
