import { Dictionary } from "_common/type/utils";
import { lazy } from "react";
import { replaceAll } from "_common/service/FunUtil";
import CalendarPage from "business/calendar/CalendarPage";
const Home = lazy(() => import("business/home/Home"));
const About = lazy(() => import("business/about/About"));
const TopicPage = lazy(() => import("business/topic/TopicPage"));
const ArticlePage = lazy(() => import("business/article/ArticlePage"));
const LoginForm = lazy(() => import("_core/authent/LoginForm"));
const MyAccountPage = lazy(() => import("_core/authent/MyAccountPage"));
const ContactPage  = lazy(() => import("business/contact/ContactPage"));

export type RouteConfig = {
  path: string;
  key: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  secured: boolean;
  exact: boolean;
};

export const ROUTE_ABOUT = "/about";
export const ROUTE_TOPIC = "/rubrique/:id";
export const ROUTE_ARTICLE = "/article/:topicId/:id";
export const ROUTE_CONTACT = "/article/:topicId/contact";
export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_ACCOUNT = "/account";
export const ROUTE_CALENDRIER = "/calendrier";

export const resolvePath = (path: string, variables: Dictionary) => {
  if (path.indexOf(":") > -1) {
    Object.keys(variables).forEach((key) => {
      path = replaceAll(path, ":" + key, variables[key]);
    });
  }
  return path;
};

const routes = [
  {
    path: ROUTE_TOPIC,
    key: "topic",
    component: TopicPage,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_CONTACT,
    key: "contact",
    component: ContactPage,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_ARTICLE,
    key: "article",
    component: ArticlePage,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_ABOUT,
    key: "about",
    component: About,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_LOGIN,
    key: "login",
    component: LoginForm,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_ACCOUNT,
    key: "account",
    component: MyAccountPage,
    secured: true,
    exact: true,
  },
  {
    path: ROUTE_CALENDRIER,
    key: "calendrier",
    component: CalendarPage,
    secured: true,
    exact: false,
  },
  {
    path: ROUTE_HOME,
    key: "home",
    component: Home,
    secured: true,
    exact: false,
  },
];

export default routes;
